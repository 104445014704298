import React from 'react';
import styled from 'styled-components';

const AnchorLink = React.memo(props => {
  return (
    <AnchorLinkWrapper
      id={props.id}
      aria-hidden={true}
    />
  )
});

export default AnchorLink;

const AnchorLinkWrapper = styled.a`
  top: -115px;
  display: block;
  position: relative;
  visibility: hidden;
`;
import React from "react";
import Helmet from "react-helmet";
import config from "../../../data/SiteConfig";
import "../Styles/reset.css";
import "../Styles/style.css";
import "../Styles/animations.css";
import "../Styles/grid.css";
import Theme, { GlobalStyle } from "../Styles/Theme";
import styled, { ThemeProvider } from "styled-components";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import SEO from "../SEO/SEO";
import { graphql, StaticQuery } from "gatsby";
import { setConfig } from "react-hot-loader";

setConfig({ pureSFC: true });

const MainLayout = React.memo(props => {
  const getLocalTitle = () => {
    const pathPrefix = config.pathPrefix ? config.pathPrefix : "/";

    const currentPath = props.location.pathname
      .replace(pathPrefix, "")
      .replace("/", "");
    let title = "";
    if (currentPath === "") {
      title = "Home";
    } else if (currentPath === "latest-news") {
      title = "Latest News";
    } else if (currentPath === "apply-for-funding") {
      title = "Apply for Funding";
    } else if (currentPath === "terms-of-service") {
      title = "Terms of Service";
    } else if (currentPath === "privacy-policy") {
      title = "Privacy Policy";
    }
    return title;
  };

  return (
    <>
      <SEO />
      <Helmet>
        <title>{`${config.siteTitle} |  ${getLocalTitle()}`}</title>
      </Helmet>
      <ThemeProvider theme={Theme}>
        <>
          <GlobalStyle />
          <Header />
          <Main>{props.children}</Main>
          <Footer config={config} />
          <div id="modal-root" />
        </>
      </ThemeProvider>
    </>
  );
});

const Main = styled.main`
  padding-top: ${props => props.theme.headerHeight};
  min-height: 50vh;
`;

export default MainLayout;

import React from "react";
import styled from "styled-components";

const icons = {
  facebook:
    "M448 80v352c0 26.5-21.5 48-48 48h-85.3V302.8h60.6l8.7-67.6h-69.3V192c0-19.6 5.4-32.9 33.5-32.9H384V98.7c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9H184v67.6h60.9V480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z",
  twitter:
    "M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z",
  instagram:
    "M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z",
  linkedIn:
    "M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z",
  telegram:
    "M446.7 102.3l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1l-236 148.6L16.2 256c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 70.1c18.4-6.9 34.5 4.1 28.5 32.2z",
  check:
    "M475.2 66.2L151.3 390.1 36.8 275.6c-5.4-5.4-14.2-5.4-19.6 0L4.1 288.7c-5.4 5.4-5.4 14.2 0 19.6l137.4 137.4c5.4 5.4 14.2 5.4 19.6 0L507.9 99c5.4-5.4 5.4-14.2 0-19.6l-13.1-13.1c-5.4-5.5-14.2-5.5-19.6-.1z",
  arrow:
    "M506.9 247.4l-8.1-8.1c-5.4-5.4-14.1-5.4-19.4 0L275.5 443.2V13.7c0-7.5-6.2-13.7-13.7-13.7h-11.4c-7.5 0-13.7 6.2-13.7 13.7v429.5l-204-204c-5.4-5.4-14.1-5.4-19.4 0l-8.1 8.1c-5.4 5.4-5.4 14.1 0 19.4l241 241.3c5.4 5.4 14.1 5.4 19.4 0l241.1-241.2c5.5-5.5 5.5-14.1.2-19.4z",
  close:
    "M310.31 256.01l164.1-164.1 33.84-33.84c4.99-4.99 4.99-13.1 0-18.1l-36.2-36.21c-4.99-4.99-13.1-4.99-18.1 0L256.01 201.7 58.07 3.74c-4.99-4.99-13.1-4.99-18.1 0L3.74 39.95c-4.99 4.99-4.99 13.1 0 18.1L201.7 256.01 3.74 453.95c-4.99 4.99-4.99 13.1 0 18.1l36.21 36.21c4.99 4.99 13.1 4.99 18.1 0l197.96-197.94 164.1 164.1 33.84 33.84c4.99 4.99 13.1 4.99 18.1 0l36.21-36.21c4.99-4.99 4.99-13.1 0-18.1L310.31 256.01z",
  minus:
    "M424 232H56c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8z",
  plus:
    "M424 232.5H264v-160c0-4.4-3.6-8-8-8h-32c-4.4 0-8 3.6-8 8v160H56c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h160v160c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8v-160h160c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8z",
  link:
    "M282.3 386l-74.2 74.2c-47.6 47.6-124.7 47.6-172.3 0-47.6-47.6-47.6-124.7 0-172.3l74.2-74.2c1.1-1.1 2.2-2.1 3.3-3.2 7.1-6.6 18.6-1.9 18.9 7.8.2 4.5.6 9 1.1 13.5.5 3.5-.7 7-3.2 9.5l-70.6 70.6c-34.4 34.5-34.4 90.2 0 124.6 34.5 34.4 90.2 34.4 124.6 0l74.2-74.2.3-.3c34-34.4 33.9-90.1-.3-124.3-7.7-7.7-16.5-13.7-25.9-17.9-4.3-1.9-7-6.3-6.7-10.9.3-4.9 1.2-9.7 2.6-14.3 2-6.2 8.9-9.3 14.9-6.8 14.1 5.9 27.4 14.6 38.8 26.1 47.8 47.3 47.8 124.6.3 172.1zm-84.6-87.7c11.5 11.5 24.7 20.2 38.8 26.1 6 2.5 13-.6 14.9-6.8 1.5-4.6 2.4-9.4 2.6-14.3.3-4.7-2.4-9-6.7-10.9-9.4-4.2-18.2-10.2-25.9-17.9-34.2-34.2-34.4-89.9-.3-124.3l.3-.3 74.2-74.2c34.5-34.4 90.2-34.4 124.6 0 34.4 34.5 34.4 90.2 0 124.6l-70.6 70.6c-2.5 2.5-3.6 6-3.2 9.5.6 4.5 1 9 1.1 13.5.4 9.6 11.9 14.3 18.9 7.8 1.1-1 2.2-2.1 3.3-3.2l74.2-74.2c47.6-47.6 47.6-124.7 0-172.3-47.6-47.6-124.7-47.6-172.3 0l-73.9 74c-47.5 47.5-47.5 124.8 0 172.3z",
  map:
    "M204.3 501.7C59 291 32 269.4 32 192 32 86 118 0 224 0s192 86 192 192c0 77.4-27 99-172.3 309.7-9.5 13.7-29.9 13.7-39.4 0zM224 272c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80z",
  eye:
    "M442.8 237C399 162.6 317.2 112.9 224 112.9c-93.2 0-175 49.8-218.8 124.2-6.9 11.7-6.9 26.2 0 37.9C49 349.4 130.8 399.1 224 399.1c93.2 0 175-49.8 218.8-124.2 6.9-11.6 6.9-26.2 0-37.9zm-119.2-2.8c0 53.3-44.6 96.4-99.6 96.4s-99.6-43.2-99.6-96.4 44.6-96.4 99.6-96.4 99.6 43.2 99.6 96.4zm97.8 28.1C381 330.9 306.3 374.2 224 374.2c-82.7 0-157.2-43.6-197.4-111.9-2.3-3.9-2.3-8.7 0-12.6 22.6-38.4 57-70.3 99.3-90.1-16.4 20.4-26.3 46.3-26.3 74.7C99.6 301 155 355.6 224 355.6S348.4 301 348.4 234.3c0-28.3-10-54.3-26.3-74.7 41.8 19.6 76.4 51.3 99.3 90.1 2.3 3.9 2.3 8.7 0 12.6zm-247.2-31.2c0-8.5 2.1-16.5 5.9-23.4 0 11.3 9.2 20.5 20.5 20.5s20.5-9.2 20.5-20.5-9.2-20.5-20.5-20.5c7-3.7 15-5.9 23.4-5.9 27.5 0 49.8 22.3 49.8 49.8s-22.3 49.8-49.8 49.8-49.8-22.3-49.8-49.8z",
  lock:
    "M224 420c-11 0-20-9-20-20v-64c0-11 9-20 20-20s20 9 20 20v64c0 11-9 20-20 20zm224-148v192c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48h16v-64C64 71.6 136-.3 224.5 0 312.9.3 384 73.1 384 161.5V224h16c26.5 0 48 21.5 48 48zM96 224h256v-64c0-70.6-57.4-128-128-128S96 89.4 96 160v64zm320 240V272c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16v192c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16z",
  target:
    "M224 60.9c107.2 0 195.1 86.8 195.1 195.1 0 107.2-86.8 195.1-195.1 195.1-107.2 0-195.1-86.8-195.1-195.1 0-107.2 86.8-195.1 195.1-195.1m0-28.9C100.3 32 0 132.3 0 256s100.3 224 224 224 224-100.3 224-224S347.7 32 224 32zm0 115.6c59.9 0 108.4 48.5 108.4 108.4 0 59.9-48.5 108.4-108.4 108.4-59.9 0-108.4-48.5-108.4-108.4 0-59.9 48.5-108.4 108.4-108.4m0-28.9c-75.8 0-137.3 61.5-137.3 137.3S148.2 393.3 224 393.3 361.3 331.8 361.3 256 299.8 118.7 224 118.7zm0 86.7c-27.9 0-50.6 22.6-50.6 50.6 0 27.9 22.6 50.6 50.6 50.6s50.6-22.6 50.6-50.6-22.7-50.6-50.6-50.6z",
  chart:
    "M227.5 53.4v188.2H416c6.1 0 11-5.2 10.7-11.3C421 129.4 339.8 48.4 238.8 42.7c-6.1-.4-11.3 4.5-11.3 10.7zM256 72.9c71.4 11.9 128.3 68.7 140.2 140.2H256V72.9zM248.9 270l133.3 133.5c4.4 4.4 11.5 4.1 15.6-.4 31-34.7 47.8-78.2 50.2-121.8.4-6.1-4.5-11.3-10.7-11.3H248.9zm139.4 99.3l-70.8-71h99.9c-4.2 25.5-14.1 50.1-29.1 71zM199.1 270V81.7c0-6.1-5.2-11-11.4-10.7C83.2 77 .2 163.8 0 269.6c-.3 171.6 204.1 263.1 331.6 149.3 4.6-4.1 4.8-11.2.4-15.6L199.1 270zm-170.7 0c0-85.9 62.9-155.2 142.2-168.4V270c0 14.5-2.1 9.6 8.4 20.1l119 119.5c-112 79.8-269.6-1-269.6-139.6z",
  hands:
    "M307.4 150.7L240 83.3c-4.3-4.3-10-6.5-15.7-6.5-5.7 0-11.3 2.2-15.7 6.5l-67.5 67.5c-8.6 8.6-8.6 22.7 0 31.3l67.5 67.5c4.3 4.3 10 6.5 15.7 6.5 5.7 0 11.3-2.2 15.7-6.5l67.5-67.5c8.6-8.7 8.6-22.7-.1-31.4zm-83.1 83.1l-67.5-67.5 67.4-67.5 67.5 67.5-67.4 67.5zM154 250.9c-9.8-13.4-34.4-22-52.1-2.7-10.9 11.8-11.1 30-1.7 42.9l20 27.5c4.5 6.2-4.5 13.4-9.5 7.5l-43.4-51.3v-96c0-18.2-14.8-34.5-33-34.8-19-.3-34.3 14.8-34.3 33.6v119.3c0 7.6 2.6 15 7.3 21l74.9 93.6c3.8 4.8 6.2 12.2 7.1 18.9.3 2.8 2.8 4.8 5.6 4.8h11.2c3.4 0 5.9-2.7 5.6-6.1-1.1-11.2-5.2-23.3-12-31.6l-74.9-93.6c-1.6-2-2.4-4.5-2.4-7V177.6c0-14.7 22.4-15.1 22.4.5v104.8L90 337.1c25.8 30.9 67.7-1.9 49.6-29.7-.1-.2-.3-.3-.3-.6l-21.4-29.5c-3.3-4.5-2.9-11.7 2.4-15.6 4.9-3.6 12-2.7 15.7 2.4l29.7 40.9c8.9 11.8 13.6 26.2 13.6 40.6v84c0 3.1 2.5 5.6 5.6 5.6H196c3.1 0 5.6-2.5 5.6-5.6v-84c0-19.4-6.3-38.2-17.9-53.8L154 250.9zm293.9-73.3c0-18.8-15.3-33.9-34.2-33.6-18.2.3-33 16.6-33 34.8v96L337.3 326c-5 5.9-14.1-1.2-9.5-7.5l20-27.5c9.4-12.9 9.2-31.2-1.7-42.9-17.8-19.2-42.4-10.7-52.1 2.7l-29.7 40.9c-11.6 15.5-17.9 34.4-17.9 53.8v84c0 3.1 2.5 5.6 5.6 5.6h11.2c3.1 0 5.6-2.5 5.6-5.6v-84c0-14.4 4.8-28.8 13.6-40.6l29.7-40.9c3.7-5.1 10.7-6.1 15.7-2.4 5.5 3.9 5.8 11.1 2.4 15.6l-21.4 29.5c-.1.2-.3.3-.3.6-18.3 27.8 23.7 60.7 49.5 29.7l45.2-54.2V178c0-15.6 22.4-15.2 22.4-.5v119.3c0 2.5-.8 5-2.4 7l-74.9 93.6c-6.6 8.3-10.8 20.4-12 31.6-.3 3.4 2.2 6.1 5.6 6.1h11.2c2.8 0 5.2-2 5.6-4.8.8-6.7 3.2-14.1 7.1-18.9l74.9-93.6c4.8-5.9 7.3-13.4 7.3-21l-.1-119.2z",
  key:
    "M294 60c69.6 0 126 56.4 126 126s-56.4 126-126 126c-16.6 0-32.4-3.2-47-9l-37 37h-42v56h-56v56H28v-70l149-149c-5.8-14.5-9-30.4-9-47 0-69.6 56.4-126 126-126m0-28c-85 0-154 68.9-154 154 0 13.4 1.7 26.6 5.1 39.3L6.2 364.3c-3.9 3.9-6.2 9.3-6.2 14.8V459c0 11.6 9.4 21 21 21h98c11.6 0 21-9.4 21-21v-35h35c11.6 0 21-9.4 21-21v-35h16.9c5.6 0 10.9-2.2 14.8-6.2l26.9-26.9c12.8 3.4 25.9 5.1 39.3 5.1 85 0 154-68.9 154-154C448 101 379.1 32 294 32zm42 94.5c9.6 0 17.5 7.9 17.5 17.5s-7.9 17.5-17.5 17.5-17.5-7.9-17.5-17.5 7.9-17.5 17.5-17.5m0-24.5c-23.2 0-42 18.8-42 42s18.8 42 42 42 42-18.8 42-42-18.8-42-42-42z",
  mail:
    "M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z",
  loading:
    "M288 32a32 32 0 1 1-64 0 32 32 0 0 1 64 0zm-32 416a32 32 0 1 0 0 64 32 32 0 0 0 0-64zm256-192a32 32 0 1 0-64 0 32 32 0 0 0 64 0zm-448 0a32 32 0 1 0-64 0 32 32 0 0 0 64 0zm33.6 126.4a32 32 0 1 0 0 64 32 32 0 0 0 0-64zm316.8 0a32 32 0 1 0 0 64 32 32 0 0 0 0-64zM97.6 65.6a32 32 0 1 0 0 64 32 32 0 0 0 0-64z",
  quotes:
    "M198.3 101c-42.8 8.1-72.9 39.1-90.3 93.1-8.7 26.7-13 53.1-13 79.1 0 3.1.1 5.7.5 7.9.3 2.2.8 7 1.4 14.4h101.5v202.9H0v-187c0-91.9 18.3-162.6 54.9-212.3 36.6-49.6 84.4-78.2 143.4-85.6V101zm313.7 0c-34.1 5.6-60 24.8-77.7 57.7-17.7 32.9-26.5 70.7-26.5 113.6 0 3.7.1 7.5.5 11.2.3 3.7 1.1 7.8 2.3 12.1H512v202.9H312.8V311.4c0-73.9 15.5-139.8 46.5-197.8S441.2 22.2 512 13.5V101z",
  "arrow-right":
    "M247.4 505.8c5.3 5.3 13.9 5.3 19.4-.2L508 264.5c5.4-5.3 5.4-14 0-19.4L266.7 4.1c-5.3-5.4-14-5.4-19.4 0l-8.1 8.1c-5.4 5.3-5.4 14 0 19.4l204 204H13.7c-7.5 0-13.7 6.2-13.7 13.7v11.4c0 7.5 6.2 13.7 13.7 13.7h429.5L239.3 478.2c-5.4 5.3-5.4 14 0 19.4l8.1 8.2z",
  "arrow-left":
    "M264.7 505.8l8.1-8.1c5.4-5.4 5.4-14.1 0-19.4L68.9 274.4h429.5c7.5 0 13.7-6.2 13.7-13.7v-11.4c0-7.5-6.2-13.7-13.7-13.7H68.9l204-204c5.4-5.4 5.4-14.1 0-19.4l-8.1-8.1c-5.4-5.4-14.1-5.4-19.4 0L4.1 245.1c-5.4 5.4-5.4 14.1 0 19.4l241.2 241.1c5.5 5.5 14.1 5.5 19.4.2z",
};

const Icon = ({
  name,
  iconWidth,
  iconHeight,
  style,
  viewBoxWidth,
  viewBoxHeight,
}) => {
  return (
    <IconWrapper style={style} className="icon-wrapper">
      <Svg
        viewBox={`0 0 ${viewBoxWidth || "448"} ${viewBoxHeight || "512"}`}
        iconWidth={iconWidth}
        iconHeight={iconHeight}
        role="img"
        aria-labelledby={`icon-${name}`}
        className="icon">
        <title id={`icon-${name}`}>{name}</title>
        <path d={icons[name] ? icons[name] : ""} />
      </Svg>
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  color: currentColor;
  margin: 0 auto;
`;

const Svg = styled.svg`
  fill: currentColor;
  stroke: currentColor;
  width: ${props => (props.iconWidth ? `${props.iconWidth}px` : "80px")};
  height: ${props => (props.iconHeight ? `${props.iconHeight}px` : "80px")};

  @media (min-width: ${props => props.theme.lg}) {
    width: ${props => (props.iconWidth ? `${props.iconWidth}px` : "100px")};
    height: ${props => (props.iconHeight ? `${props.iconHeight}px` : "100px")};
  }
`;

const ButtonIconStyled = styled.span`
  width: ${props => `${props.width || 20}px`};
  height: ${props => `${props.height || 20}px`};
  transform-origin: 50% 50%;
  margin-left: 1rem;

  .icon-wrapper {
    width: ${props => `${props.width || 20}px`};
    height: ${props => `${props.height || 20}px`};
    line-height: 20px;
  }
`;

const ButtonIcon = ({
  name,
  iconWidth,
  iconHeight,
  style,
  viewBoxWidth,
  viewBoxHeight,
}) => {
  return (
    <ButtonIconStyled
      className={name === "loading" ? "spinner" : ""}
      width={iconWidth}
      height={iconHeight}>
      <Icon
        style={style}
        name={name}
        iconWidth={iconWidth || 20}
        iconHeight={iconHeight || 20}
        viewBoxWidth={viewBoxWidth || 512}
        viewBoxHeight={viewBoxHeight || 512}
      />
    </ButtonIconStyled>
  );
};

export { Icon, ButtonIcon };

module.exports = {
  siteTitle: "Finova Capital", // Site title.
  siteTitleAlt: "Finova Capital", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024x1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.finova-capital.com", // Domain of your website without pathPrefix.
  siteDescription:
    "Finova Capital is an open ended investment fund focused on ventures related to blockchain and other distributed ledger technologies, that offers a unique opportunity for eligible investors to invest in a diversified portfolio consisting of equity, cryptocurrencies and digital assets.", // Website description used for RSS feeds/meta description tag.
  siteFBAppID: "", // FB Application ID for using app insights
  googleAnalyticsID: "UA-131020855-1", // GA tracking ID.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  postDefaultCategoryID: "", // Default category for posts.
  copyright: "© 2018 Finova Capital Ltd. All rights reserved.", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#074184", // Used for setting manifest and progress theme colors.
  backgroundColor: "#0A0625", // Used for setting manifest background color.
};

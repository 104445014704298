import styled from "styled-components";

const Button = styled.a`
  color: white;
  border-radius: 4px;
  height: 50px;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.25s background-color cubic-bezier(0.4, 0, 0.6, 1),
    0.15s border-color ease-out;
  border: 1px solid transparent;
  font-weight: 600;
  line-height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  margin-bottom: 1.5rem;
  background-color: ${props => props.theme.main};
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  min-width: 260px;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.mainLight};
  }
`;

const ButtonWhite = styled(Button)`
  background-color: white;
  color: ${props => props.theme.main};

  &:hover {
    background-color: transparent;
    color: white;
    border-color: white;
  }
`;

const ButtonOutlineWhite = styled(Button)`
  background-color: transparent;
  border-color: white;
  color: white;

  &:hover,
  &:focus {
    background-color: white;
    color: ${props => props.theme.main};
    border-color: white;
  }
`;

const ButtonOutlineLight = styled(ButtonOutlineWhite)`
  border-color: rgba(255, 255, 255, 0.3);
`;

const ButtonOutlineBlue = styled(ButtonOutlineWhite)`
  border-color: ${props => props.theme.mainLight3};
  color: ${props => props.theme.mainLight2};

  &:hover,
  &:focus {
    color: ${props => props.theme.main};
    border-color: ${props => props.theme.main};
  }
`;

const ButtonLink = styled.a`
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
  line-height: 42px;
  outline: 0;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: transform 0.25s ease-out;
  display: inline-block;

  &:hover {
    transform: translateX(2px);
  }
`;

const ButtonRightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${props => props.theme.md}) {
    justify-content: flex-end;
  }
`;

export {
  Button,
  ButtonOutlineWhite,
  ButtonLink,
  ButtonOutlineLight,
  ButtonRightWrapper,
  ButtonOutlineBlue,
};

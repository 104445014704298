import React from "react";
import { FormGroup, Input, FormLabel, InputPrefix } from "../Styles/Form";

const FormInput = React.memo(props => {
  const { label, name, prefix } = props;
  return (
    <FormGroup>
      { label && label.length > 0 && <FormLabel htmlFor={name}>{label}</FormLabel>}
      { prefix && <InputPrefix>{prefix}</InputPrefix>}
      <Input {...props} id={name} style={{ paddingLeft: prefix ? '62px' : '' }} />
    </FormGroup>
  );
});

export default FormInput;

import React, { PureComponent } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Transition } from "react-transition-group";
import { Button } from "../../Styles/Buttons";

export default class Header extends PureComponent {
  state = {
    open: false,
    hasScrolled: false,
    compressHeader: false
  };

  toggleMenu() {
    if (this.state.open === true) this.setState({ open: false });
  }

  handleHeaderSize = () => {
    window.pageYOffset > 300
      ? this.setState(() => ({
        compressHeader: true
      }))
      : this.setState(() => ({
        compressHeader: false
      }));

    window.pageYOffset > 5
      ? this.setState(() => ({
        hasScrolled: true
      }))
      : this.setState(() => ({
        hasScrolled: false
      }));
  };

  componentDidMount() {
    if (window.pageYOffset > 5) {
      this.setState(() => ({
        hasScrolled: true
      }));
    }

    if (window.pageYOffset > 300) {
      this.setState(() => ({
        compressHeader: true
      }));
    }

    window.addEventListener("scroll", this.handleHeaderSize);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleHeaderSize);
  }

  closeMobileMenu = () => {
    this.setState({
      open: false
    })
  };

  render() {
    const { open, hasScrolled, compressHeader } = this.state;
    return (
      <HeaderWrapper className={ `${ hasScrolled ? " isCompressed" : "" } ${
        open ? "navIsOpen" : ""
        }` } compressHeader={ compressHeader }>
        <HeaderNav className={ `container ${ open ? "navIsOpen" : "" }` }>
          <HeaderLogoMenu>
            <NavLogo>
              <Link to={ "/" } title="Logotype">
                <svg viewBox="0 0 597.4 82" role="img" aria-labelledby="header-logo">
                  <title id="header-logo">Finova Capital Logotype</title>
                  <path
                    d="M104.9 59.9V15.6h30.3v7.5h-21.4v10.5h18.5v7.5h-18.5v18.8h-8.9zM142.3 59.9V15.6h8.9v44.3h-8.9zM159.9 59.9V15.6h8.7l18.1 29.6V15.6h8.3v44.3h-9L168.2 31v28.9h-8.3zM202.6 38c0-4.5.7-8.3 2-11.4 1-2.3 2.4-4.3 4.1-6.1s3.6-3.1 5.7-4c2.8-1.2 5.9-1.8 9.5-1.8 6.5 0 11.7 2 15.7 6.1 3.9 4 5.9 9.7 5.9 16.9 0 7.1-1.9 12.7-5.8 16.8-3.9 4-9.1 6.1-15.6 6.1-6.6 0-11.8-2-15.7-6s-5.8-9.5-5.8-16.6zm9.2-.3c0 5 1.2 8.8 3.5 11.4 2.3 2.6 5.3 3.9 8.8 3.9s6.5-1.3 8.8-3.8 3.4-6.4 3.4-11.5-1.1-8.9-3.3-11.4c-2.2-2.5-5.2-3.7-8.9-3.7s-6.7 1.3-8.9 3.8c-2.2 2.4-3.4 6.2-3.4 11.3zM263.8 59.9L248 15.6h9.7l11.2 32.8 10.8-32.8h9.5l-15.9 44.3h-9.5zM329.1 59.9h-9.7l-3.9-10.1h-17.7l-3.7 10.1h-9.5l17.2-44.3h9.5l17.8 44.3zm-16.4-17.5L306.6 26l-6 16.4h12.1z"
                    className="st0" />
                  <path
                    d="M382.9 44.4l5.9 1.5c-1.2 4.8-3.4 8.5-6.6 11-3.2 2.5-7.1 3.8-11.7 3.8-4.8 0-8.7-1-11.6-2.9-3-1.9-5.3-4.8-6.8-8.4-1.6-3.7-2.3-7.6-2.3-11.9 0-4.6.9-8.6 2.6-12.1 1.8-3.4 4.3-6 7.5-7.8 3.2-1.8 6.8-2.7 10.7-2.7 4.4 0 8.2 1.1 11.2 3.4s5.1 5.4 6.3 9.5l-5.8 1.4c-1-3.2-2.5-5.6-4.5-7s-4.4-2.2-7.4-2.2c-3.4 0-6.2.8-8.5 2.4s-3.9 3.8-4.8 6.6-1.4 5.6-1.4 8.5c0 3.8.5 7 1.6 9.9 1.1 2.8 2.8 4.9 5.1 6.3 2.3 1.4 4.8 2.1 7.5 2.1 3.3 0 6.1-.9 8.3-2.8s3.9-4.9 4.7-8.6zM391.1 59.9l17-44.3h6.3l18.1 44.3h-6.7l-5.2-13.4h-18.5l-4.9 13.4h-6.1zm12.8-18.2h15l-4.6-12.3c-1.4-3.7-2.5-6.8-3.1-9.2-.6 2.8-1.4 5.7-2.4 8.5l-4.9 13zM437.2 59.9V15.6h16.7c2.9 0 5.2.1 6.7.4 2.2.4 4 1.1 5.5 2.1s2.7 2.4 3.5 4.3 1.3 3.8 1.3 6c0 3.8-1.2 6.9-3.6 9.6-2.4 2.6-6.7 3.9-13 3.9H443v18h-5.8zm5.8-23.2h11.4c3.8 0 6.5-.7 8.1-2.1 1.6-1.4 2.4-3.4 2.4-5.9 0-1.9-.5-3.4-1.4-4.8a6.5 6.5 0 0 0-3.7-2.6c-1-.3-2.8-.4-5.5-.4H443v15.8zM479.4 59.9V15.6h5.9v44.3h-5.9zM506.9 59.9v-39h-14.6v-5.2h35.1v5.2h-14.6v39h-5.9zM523.9 59.9l17-44.3h6.3l18.1 44.3h-6.7l-5.2-13.4H535l-4.9 13.4h-6.2zm12.8-18.2h15l-4.6-12.3c-1.4-3.7-2.5-6.8-3.1-9.2-.6 2.8-1.4 5.7-2.4 8.5l-4.9 13zM569.8 59.9V15.6h5.9v39h21.8v5.2h-27.7z"
                    className="st1" />
                  <g>
                    <path
                      d="M77 42.2A36.14 36.14 0 0 1 41 77C20.9 77 5.2 60 5 41 4.9 24.4 16.7 9.3 33.7 5c.2-1.5.3-3 .5-4.5-1.8.4-3.5.9-5.3 1.3A41.15 41.15 0 0 0 0 41c0 22.6 18.4 41 41 41 22.2 0 40.3-17.7 41-39.8h-5z"
                      className="st1" />
                    <path
                      d="M82 40.4l-.1-1.8h.1l-.3-3.2H43.5V5.1c14.5 1 26.6 10.6 31.3 23.7 1.5-.8 3-1.6 4.4-2.5A40.83 40.83 0 0 0 41 0c-.8 0-1.6 0-2.4.1l-.1-.1v72.9h5V40.4H82z"
                      className="st0" />
                  </g>
                </svg>
              </Link>
            </NavLogo>
            <NavMenuButton aria-label="Menu" aria-controls="navigation" className={ open ? "isOpen" : "" }
                           onClick={ () =>
                             this.setState(state => ({
                               open: !state.open
                             }))
                           }>
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </NavMenuButton>
          </HeaderLogoMenu>
          <Transition in={ open } timeout={ {
            enter: 0,
            exit: 250
          } }>
            { status => (
              <NavLinks className={ `${ status }` }>
                { [
                  "Home",
                  "About",
                  "Team",
                  "Partners",
                  "Funding",
                  "News",
                  "Contact"
                ].map((item, index) => (
                  <li key={ index }>
                    <Link onClick={ this.closeMobileMenu } to={ `#${
                      item !== "Home" ? item.toLocaleLowerCase() : ""
                      }` }>
                      { item }
                    </Link>
                  </li>
                )) }
                <LinkButton>
                  <Button to={ "#invest" } as={ Link } onClick={ this.closeMobileMenu }>
                    Invest
                  </Button>
                </LinkButton>
              </NavLinks>
            ) }
          </Transition>
        </HeaderNav>
      </HeaderWrapper>
    );
  }
}

const HeaderNav = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: height 0.15s ease-out;

  @media (min-width: ${ props => props.theme.lg }) {
    flex-direction: row;
    height: ${ props => props.theme.headerHeight };
  }
`;

const NavLogo = styled.div`
  overflow: hidden;
  width: 200px;
  margin-top: 8px;

  a {
    color: ${ props => props.theme.main };
    height: 40px;
  }

  svg {
    padding: 2px;

    .st0 {
      fill: white;
    }

    .st1 {
      fill: white;
    }
  }

  ${ HeaderNav }.isCompressed & {
    padding: 2px;
  }
`;

const NavLinks = styled.ul`
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  margin: 1rem 0;
  height: 0;
  opacity: 0;
  transition: opacity 0.3s 0.15s ease-out, height 0.2s ease-out;
  display: none;

  &.entering,
  &.entered,
  &.exiting {
    display: flex;
  }

  &.entering,
  &.exiting {
    height: 1px;
  }

  &.entered {
    opacity: 1;
    height: 80vh;
  }

  &.exiting {
    transition: opacity 0.15s ease-out, height 0.15s 0.15s ease-out;
  }

  @media (min-width: ${ props => props.theme.lg }) {
    width: auto;
    display: block;
    height: auto;
    text-align: left;
    opacity: 1;
  }

  li {
    display: block;
    padding: 0.5rem;

    a {
      transition: opacity 0.15s ease-out;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: white;

      &:hover,
      &:focus,
      &:active,
      &.isActive {
        opacity: 0.7;
      }
    }
    
    &:last-of-type {
      a {
        &:hover {
          opacity: 0.9;
        }
      }
    }

    @media (min-width: ${ props => props.theme.sm }) {
      padding: 1rem;
    }
    
    @media (min-width: ${ props => props.theme.lg }) {
      display: inline-block;
      padding: 0.5rem;

      &:last-of-type {
        padding-right: 0;
      }
    }

    @media (min-width: ${ props => props.theme.xl }) {
      padding: 0 1rem;
    }
  }

  a {
    font-size: 13px;
    color: ${ props => props.theme.text };
    text-decoration: none;
    transition: color 0.15s ease-in;
  }
`;

const NavMenuButton = styled.button`
  display: inline-block;
  cursor: pointer;
  height: 46px;
  width: 46px;
  border-radius: 4px;
  padding: 7px 0;
  outline: 0;
  transition: background-color 0.25s ease-out;

  &:focus,
  &:active,
  &:hover {
    background-color: #f6f6f6;

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: #82868a;
    }
  }

  .hamburger-box {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 32px;
  }

  .hamburger-inner {
    position: absolute;
    width: 30px;
    height: 4px;
    transition-property: transform;
    border-radius: 4px;
    background-color: white;
    top: 13px;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 75ms;

    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 30px;
      height: 4px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform;
      border-radius: 4px;
      background-color: white;
      left: 0;
    }

    &::before {
      top: -10px;
      transition: top 75ms ease 0.12s, opacity 75ms ease;
    }

    &::after {
      bottom: -10px;
      transition: bottom 75ms ease 0.12s,
        transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.isOpen {
    .hamburger-inner {
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(45deg);
      background-color: ${ props => props.theme.main };

      &::before,
      &::after {
        background-color: ${ props => props.theme.main };
      }
      &::before {
        top: 0;
        transition: top 75ms ease, opacity 75ms ease 0.12s;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transition: bottom 75ms ease,
          transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        transform: rotate(-90deg);
      }
    }
  }

  @media (min-width: ${ props => props.theme.lg }) {
    display: none;
  }
`;

const HeaderLogoMenu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${ props => props.theme.lg }) {
    display: block;
    width: auto;
    padding: 0;
  }
`;

const LinkButton = styled.li`
  display: inline-flex;

  a {
    min-width: auto;
    padding: 0 1rem;
    margin-bottom: 0;
    height: 38px;
    color: white;

    &:hover,
    &:focus,
    &:active {
      color: white !important;
    }
  }
`;

const HeaderWrapper = styled.nav`
  position: fixed;
  transform: translateZ(0);
  width: 100%;
  z-index: 100;
  transition: background 0.45s ease-out, height 0.15s ease-out;
  padding-top: 3px;
  box-shadow: ${ props =>
  props.compressHeader ? "0 2px 20px rgba(0, 0, 0, 0.075)" : "none" };

  &.navIsOpen {
    height: 100vh;
    background: white;

    ${ NavLinks } {
      a {
        color: ${ props => props.theme.main };
      }

      ${ LinkButton } a {
        color: white;
      }
    }
    
    ${ NavLogo } {
      svg {
        .st0 {
          fill: ${ props => props.theme.main };
        }

        .st1 {
          fill: ${ props => props.theme.mainAccent };
        }
      }
    }
  }

  background: transparent;

  &.isCompressed {
    background: white;

    ${ NavLinks } {
      a {
        color: ${ props => props.theme.main };
      }
    }

    ${ LinkButton } a {
      color: white;
    }

    ${ NavMenuButton } {
      .hamburger-inner {
        background-color: ${ props => props.theme.main };

        &::after,
        &::before {
          background-color: ${ props => props.theme.main };
        }
      }
    }

    ${ NavLogo } {
      svg {
        .st0 {
          fill: ${ props => props.theme.main };
        }

        .st1 {
          fill: ${ props => props.theme.mainAccent };
        }
      }
    }
  }

  @media (min-width: ${ props => props.theme.lg }) {
    padding-top: 0;

    ${ HeaderNav } {
      height: ${ props =>
  props.compressHeader
    ? props.theme.headerMinHeight
    : props.theme.headerHeight };
    }
  }
`;

import config from "../../../data/SiteConfig";
import { createGlobalStyle } from "styled-components";

const Theme = {
  main: config.themeColor, // same color as Theme main color
  secondary: config.backgroundColor, // same color as Theme background color
  text: "#23242a",
  mainDarker: "#16325e",
  mainAccent: "#0076FF",
  mainLight: "#4361a4",
  mainLight2: "#6e86bd",
  mainLight3: "#c8d2e6",
  mainLighter: "#dde5ff",
  mainLighter2: "#F9FBFE",
  secondaryLighter: "#A7ACB6",
  grey: "#e6ebf1",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  headerHeight: "80px",
  headerMinHeight: "55px",
  footerHeight: "150px",
};

const GlobalStyle = createGlobalStyle`
  body {
    color: ${props => props.theme.text};
    
    p {
      margin: 0.5rem 0;
      display: block;
      line-height: 1.7;
    }
  }
  
  ::selection {
    background-color: ${props => props.theme.mainAccent};
    color: white;
  }

  a {
    color: ${props => props.theme.mainAccent};
  }
  
  strong, b {
    font-weight: bold;
  }
  
  .ReactModal__Body--open {
    //overflow: hidden;
    //position: fixed;
    //width: 100%;
    //height: 100%;
  }
  
  .ReactModal__Portal {
    position: relative;
    z-index: 1000;
  }
`;

export { Theme as default, GlobalStyle };

import React, { useState } from "react";
import { Input } from "../../Styles/Form";
import { Button, ButtonRightWrapper } from "../../Styles/Buttons";
import { ButtonIcon, Icon } from "../../Icons/Icons";
import Modal from "react-modal";
import styled from "styled-components";
import countryList from "../../Form/countries";
import FormInput from "../../Form/FormInput";
import { H3 } from "../../Styles/Typography";
import FormSelect from "../../Form/FormSelect";
Modal.setAppElement("#___gatsby");

const ContactModal = React.memo(
  ({ isContactModalOpen, setContactModalOpen }) => {
    const defaultValues = {
      honeypot: "",
      name: "",
      email: "",
      phoneNumber: "",
      company: "",
      country: "select",
      position: "",
    };

    const [values, setValues] = useState(defaultValues);
    const [isFormSuccess, setFormState] = useState(false);
    const [isFormLoading, setFormLoading] = useState(false);

    const handleOnChange = e => {
      const name = e.target.name;
      const target = e.target;
      const value = target.value;

      setValues({
        ...values,
        [name]: value,
      });

      if (name === "country") {
        let countrySelected = countryList.find(
          country => country.name === value
        );

        setValues({
          ...values,
          country: countrySelected.name,
          phoneCode:
            countrySelected !== undefined ? countrySelected.phone_code : "",
        });
      }
    };

    const onSubmit = e => {
      e.preventDefault();
      const emailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{1,15})$/;
      if (!emailRegex.test(values.email)) return;

      setFormLoading(true);
      fetch(
        `https://n8u69gtxf8.execute-api.us-east-1.amazonaws.com/dev/finova-capital-newsletter-form`,
        {
          method: "POST",
          body: JSON.stringify({
            ses_address: "contact@finova-capital.com",
            send_to: "contact@finova-capital.com",
            subject: "Finova Capital | Newsletter Subscription",
            name: values.name,
            company: values.company,
            position: values.position,
            email: values.email,
            phone: values.phoneCode + values.phoneNumber,
            country: values.country,
          }),
          headers: new Headers({
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json; charset=utf-8",
          }),
        }
      )
        .then(() => {
          setFormState(true);
          setFormLoading(false);
          setTimeout(() => {
            setContactModalOpen(false);
            setFormState(false);
          }, 2000);
          setValues(defaultValues);
        })
        .catch(err => {
          setFormState(false);
          setFormLoading(false);
          console.log(err);
        });
    };

    return (
      <StyledModal
        isOpen={isContactModalOpen}
        style={customStyles}
        closeTimeoutMS={0}
        bodyOpenClassName="ReactModal__Body--open"
        portalClassName="ReactModal__Portal"
        contentLabel="Newsletter subscription form"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setContactModalOpen(false)}>
        <CloseButton onClick={() => setContactModalOpen(false)}>
          <Icon
            name="close"
            iconHeight={20}
            iconWidth={20}
            viewBoxWidth={512}
          />
        </CloseButton>
        <ModalWrapper>
          <Intro>
            <H3>NEWSLETTER</H3>
            <p>Subscribe to our newsletter to receive the latest news.</p>
          </Intro>
          <form className="container" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <input
                  type="input"
                  name="honeypot"
                  readOnly={true}
                  value=""
                  style={{ display: "none" }}
                  tabIndex="-1"
                  autoComplete="off"
                />
                <FormInput
                  required
                  label="Name"
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <FormInput
                  required
                  label="Email"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <FormInput
                  required
                  label="Company Name"
                  type="text"
                  name="company"
                  value={values.company}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <FormInput
                  required
                  label="Position"
                  type="text"
                  name="position"
                  value={values.position}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <FormSelect
                  required
                  label="Country of Residency"
                  name="country"
                  value={values.country}
                  options={countryList}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <FormInput
                  required
                  label="Phone Number"
                  type="tel"
                  name="phoneNumber"
                  prefix={values.phoneCode}
                  value={values.phoneNumber}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <ButtonRightWrapper>
              <Button as="button" type="submit">
                {!isFormLoading && !isFormSuccess && <span>Subscribe</span>}
                {isFormLoading && (
                  <>
                    Subscribing
                    <ButtonIcon name="loading" />
                  </>
                )}
                {isFormSuccess &&
                  !isFormLoading && (
                    <>
                      Success
                      <ButtonIcon name="check" />
                    </>
                  )}
              </Button>
            </ButtonRightWrapper>
          </form>
        </ModalWrapper>
      </StyledModal>
    );
  }
);

const StyledModal = styled(Modal)`
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  position: fixed;
  background: white;
  transition: opacity 300ms ease-in-out;
  outline: 0;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% - 2rem);

  h3 {
    margin-bottom: 1rem;
  }

  @media (min-width: ${props => props.theme.md}) {
    width: calc(100% - 2rem);
    overflow: hidden;
    max-width: none;
  }

  @media (min-width: 1400px) {
    width: 1040px;

    h3 {
      margin-bottom: 2rem;
    }
  }
`;

const CloseButton = styled.div`
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;

  .icon-wrapper {
    padding: 10px;
    height: 40px;
    width: 40px;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0 1rem;
  height: 90vh;

  form {
    max-width: 800px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  @media (min-width: ${props => props.theme.md}) {
    padding: 3rem 0 1rem;
  }

  @media (min-width: ${props => props.theme.lg}) {
    height: auto;

    form {
      overflow: hidden;
    }
    
    button {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }
`;

const Intro = styled.div`
  text-align: center;

  @media (min-width: 1400px) {
    margin-bottom: 2rem;
  }
`;

const customStyles = {
  content: {
    padding: 0,
    borderRadius: 0,
    border: 0,
  },
};

export default ContactModal;

import React, { useState } from "react";
import styled from "styled-components";
import ContactModal from "./ContactModal";
import { ButtonOutlineLight } from "../../Styles/Buttons";

const FooterInvest = () => {
  const [isContactModalOpen, setContactModalOpen] = useState(false);

  return (
    <FooterInvestWrapper className="col-xs-12 col-md-3 col-md-offset-1">
      <h4>Newsletter</h4>
      <p>Subscribe to our newsletter to receive the latest news.</p>
      <FooterLinkButton as="button" onClick={() => setContactModalOpen(true)}>
        Subscribe
      </FooterLinkButton>
      <ContactModal
        isContactModalOpen={isContactModalOpen}
        setContactModalOpen={setContactModalOpen}
      />
    </FooterInvestWrapper>
  );
};

const FooterInvestWrapper = styled.div`
  button {
      max-width: 265px;
  width: 100%;
  min-width: auto;
  
  @media (min-width: ${ props => props.theme.lg }) {
    margin-right: 1rem;    
  }
  }
`;

const FooterLinkButton = styled(ButtonOutlineLight)`

`;

export default FooterInvest;

import styled from "styled-components";

const H1 = styled.h1`
  font-size: 40px;
  font-weight: 300;
  letter-spacing: -1px;
  line-height: 50px;
  margin-bottom: 1.5rem;

  @media (min-width: ${props => props.theme.lg}) {
    font-size: 45px;
    line-height: 55px;
  }

  @media (min-width: ${props => props.theme.xl}) {
    font-size: 50px;
    line-height: 58px;
  }
`;

const H2 = styled.h2`
  letter-spacing: -0.6px;
  padding-bottom: 0.25rem;
  font-weight: 400;
  font-size: 40px;
  color: ${props => props.theme.secondary};
  line-height: 50px;
  margin-bottom: 2rem;

  @media (min-width: ${props => props.theme.lg}) {
    font-size: 36px;
    line-height: 48px;
  }
`;

const H3 = styled.h3`
  font-weight: 600;
  line-height: 24px;
  font-size: 20px;
  color: ${props => props.theme.mainAccent};
  letter-spacing: 2.44px;
  text-transform: uppercase;
  margin-bottom: 2rem;
  
  @media (min-width: ${props => props.theme.sm}) {
    font-size: 26px;
  }
`;

const H4 = styled.h4`
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
  margin-bottom: 2rem;
`;

const ModifiedH2 = styled(H2)`
  font-size: 20px;
  font-weight: 400;
`;

const Paragraph = styled.div`
  display: inline-block;
  line-height: 2;

  p {
    padding: 0.5rem 0;
  }

  p,
  span,
  p > span {
    font-weight: 300 !important;
  }

  strong {
    font-weight: 400 !important;
  }
`;

export { H1, H2, H3, H4, ModifiedH2, Paragraph };

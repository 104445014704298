import React, { useState } from "react";
import styled from "styled-components";
import { ButtonOutlineLight } from "../../Styles/Buttons";
import AnchorLink from "../../AnchorLink";
import { Link } from "gatsby";
import { Icon } from "../../Icons/Icons";
import FooterInvest from "./FooterInvest";
import { graphql, StaticQuery } from "gatsby";

const Footer = React.memo(({ config }) => {
  return (
    <StaticQuery
      query={graphql`
        query GlobalQuery {
          allWordpressAcfOptions {
            edges {
              node {
                options {
                  contact_address {
                    address
                    google_maps_url
                    email
                  }
                  social_media_links {
                    twitter
                    telegram
                    linkedin
                  }
                }
              }
            }
          }
        }
      `}
      render={ data => {
        const { social_media_links, contact_address } = data.allWordpressAcfOptions.edges[0].node.options;
        const copyright = config.copyright;

        if (!copyright) {
          return null;
        }
        return (
          <FooterContainer className="container-fluid">
            <AnchorLink id="contact" />
            <div className="container">
              <FooterContent className="row">
                <FooterLogoContainer className="col-xs-12 col-md-3">
                  <FooterLogo href="#">
                    <svg
                      viewBox="0 0 596.4 84"
                      role="img"
                      aria-labelledby="header-logo">
                      <title id="header-logo">Finova Capital Logotype</title>
                      <path
                        d="M103.9 61.9V17.6h30.3v7.5h-21.4v10.5h18.5v7.5h-18.5v18.8h-8.9zM141.3 61.9V17.6h8.9v44.3h-8.9zM158.9 61.9V17.6h8.7l18.1 29.6V17.6h8.3v44.3h-9L167.2 33v28.9h-8.3zM201.6 40c0-4.5.7-8.3 2-11.4 1-2.3 2.4-4.3 4.1-6.1s3.6-3.1 5.7-4c2.8-1.2 5.9-1.8 9.5-1.8 6.5 0 11.7 2 15.7 6.1 3.9 4 5.9 9.7 5.9 16.9 0 7.1-1.9 12.7-5.8 16.8-3.9 4-9.1 6.1-15.6 6.1-6.6 0-11.8-2-15.7-6s-5.8-9.5-5.8-16.6zm9.2-.3c0 5 1.2 8.8 3.5 11.4 2.3 2.6 5.3 3.9 8.8 3.9s6.5-1.3 8.8-3.8 3.4-6.4 3.4-11.5-1.1-8.9-3.3-11.4c-2.2-2.5-5.2-3.7-8.9-3.7s-6.7 1.3-8.9 3.8c-2.2 2.4-3.4 6.2-3.4 11.3zM262.8 61.9L247 17.6h9.7l11.2 32.8 10.8-32.8h9.5l-15.9 44.3h-9.5zM328.1 61.9h-9.7l-3.9-10.1h-17.7l-3.7 10.1h-9.5l17.2-44.3h9.5l17.8 44.3zm-16.4-17.5L305.6 28l-6 16.4h12.1z"
                        className="st0"
                      />
                      <path
                        d="M381.9 46.4l5.9 1.5c-1.2 4.8-3.4 8.5-6.6 11-3.2 2.5-7.1 3.8-11.7 3.8-4.8 0-8.7-1-11.6-2.9-3-1.9-5.3-4.8-6.8-8.4-1.6-3.7-2.3-7.6-2.3-11.9 0-4.6.9-8.6 2.6-12.1 1.8-3.4 4.3-6 7.5-7.8 3.2-1.8 6.8-2.7 10.7-2.7 4.4 0 8.2 1.1 11.2 3.4s5.1 5.4 6.3 9.5l-5.8 1.4c-1-3.2-2.5-5.6-4.5-7s-4.4-2.2-7.4-2.2c-3.4 0-6.2.8-8.5 2.4s-3.9 3.8-4.8 6.6-1.4 5.6-1.4 8.5c0 3.8.5 7 1.6 9.9 1.1 2.8 2.8 4.9 5.1 6.3 2.3 1.4 4.8 2.1 7.5 2.1 3.3 0 6.1-.9 8.3-2.8s3.9-4.9 4.7-8.6zM390.1 61.9l17-44.3h6.3l18.1 44.3h-6.7l-5.2-13.4h-18.5l-4.9 13.4h-6.1zm12.8-18.2h15l-4.6-12.3c-1.4-3.7-2.5-6.8-3.1-9.2-.6 2.8-1.4 5.7-2.4 8.5l-4.9 13zM436.2 61.9V17.6h16.7c2.9 0 5.2.1 6.7.4 2.2.4 4 1.1 5.5 2.1s2.7 2.4 3.5 4.3 1.3 3.8 1.3 6c0 3.8-1.2 6.9-3.6 9.6-2.4 2.6-6.7 3.9-13 3.9H442v18h-5.8zm5.8-23.2h11.4c3.8 0 6.5-.7 8.1-2.1 1.6-1.4 2.4-3.4 2.4-5.9 0-1.9-.5-3.4-1.4-4.8a6.5 6.5 0 0 0-3.7-2.6c-1-.3-2.8-.4-5.5-.4H442v15.8zM478.4 61.9V17.6h5.9v44.3h-5.9zM505.9 61.9v-39h-14.6v-5.2h35.1v5.2h-14.6v39h-5.9zM522.9 61.9l17-44.3h6.3l18.1 44.3h-6.7l-5.2-13.4H534l-4.9 13.4h-6.2zm12.8-18.2h15l-4.6-12.3c-1.4-3.7-2.5-6.8-3.1-9.2-.6 2.8-1.4 5.7-2.4 8.5l-4.9 13zM568.8 61.9V17.6h5.9v39h21.8v5.2h-27.7zM77 47.3C76.3 66.2 60.9 77 42 77 24.6 77 6.9 61.5 7 41.8c.1-15.2 10.9-29.3 26.7-33.9-.1 0-.1-.1-.2-.1.2.1.3.1.3 0v-.1V.9c-.6.1-1.1.2-1.8.3-.2.1-.5.1-.7.2 0 0-.9.2-1.7.5A41.66 41.66 0 0 0 .2 45.7C2.1 68.1 21.7 84.9 44 84c21.6-1 39.3-15 40-36.7h-7z"
                        className="st1"
                      />
                      <path
                        d="M83.9 40.6v-1.1c-.1-1.1.1-4.1.1-4.1H45.5V7.2a34.97 34.97 0 0 1 29.4 22.9l.4 1.1 1-.6c1.5-.8 3-1.6 4.4-2.5l.7-.4-.2-.7C75 10.8 59.2 0 42 0c-.7 0-1.4 0-2.1.1h-1.4v74.8h7V42.4h38.6l-.2-1.8z"
                        className="st0"
                      />
                    </svg>
                  </FooterLogo>
                  <SocialIcons className="no-links">
                    {/*<a*/}
                      {/*href={social_media_links[0].twitter}*/}
                      {/*target="_blank"*/}
                      {/*rel="noopener noreferrer">*/}
                      {/*<Icon name="twitter" iconWidth={25} iconHeight={25} />*/}
                    {/*</a>*/}
                    {/*<a*/}
                      {/*href={social_media_links[0].telegram}*/}
                      {/*target="_blank"*/}
                      {/*rel="noopener noreferrer">*/}
                      {/*<Icon name="telegram" iconWidth={25} iconHeight={25} />*/}
                    {/*</a>*/}
                    {/*<a*/}
                      {/*href={social_media_links[0].linkedin}*/}
                      {/*target="_blank"*/}
                      {/*rel="noopener noreferrer">*/}
                      {/*<Icon name="linkedIn" iconWidth={25} iconHeight={25} />*/}
                    {/*</a>*/}
                  </SocialIcons>
                  <FooterLinkButton to={"/apply-for-funding"} as={Link}>
                    Apply for funding
                  </FooterLinkButton>
                  <FooterLinkButton to={"#invest"} as={Link}>
                    Apply for investing
                  </FooterLinkButton>
                </FooterLogoContainer>
                <div className="col-xs-12 col-md-3 col-md-offset-2">
                  <h4>Contact</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: contact_address[0].address,
                    }}
                  />
                  <ContactLink
                    href={contact_address[0].google_maps_url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Icon name="map" iconWidth={25} iconHeight={25} />
                    <span>Get directions</span>
                  </ContactLink>
                  <ContactLink
                    href={`mailto:${contact_address[0].email}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Icon
                      name="mail"
                      iconWidth={25}
                      iconHeight={25}
                      viewBoxWidth={512}
                    />
                    <span>contact@Finova-Capital.com</span>
                  </ContactLink>
                </div>
                <FooterInvest />
              </FooterContent>
              <FooterLegal className="row">
                <div className="col-xs-12">
                  <Link to="/terms-of-service">Terms of Service</Link>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
              </FooterLegal>
              <Copyright className="row">
                <div className="col-xs-12">{copyright}</div>
              </Copyright>
            </div>
          </FooterContainer>
        );
      }}
    />
  );
});

export default Footer;

const FooterContainer = styled.footer`
  background-color: ${props => props.theme.main};
  color: white;
  text-align: left;
  padding: 3rem 0 2rem;
  font-size: 16px;
  position: relative;
  z-index: 1;

  h4 {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 14px;
    margin-top: 1rem;

    @media (min-width: ${props => props.theme.lg}) {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    color: white;
    font-weight: 600;
  }
`;

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
  transition: opacity 0.25s ease-out;

  .icon-wrapper {
    display: inline-block;
    margin: 0 0.5rem 0 0;
    line-height: 1;
  }

  span {
    font-size: 14px;
  }

  &:last-of-type {
    .icon-wrapper {
      margin-top: 3px;
    }
  }

  &:hover,
  &:focus {
    span {
      opacity: 0.7;
    }
  }
`;

const Copyright = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  padding-top: 2rem;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  max-width: 265px;

  a {
    margin: 0.5rem 0 1rem;
  }
  
  @media (min-width: ${props => props.theme.md}) {
    max-width: 100%;
    margin: 0 auto;
  }
  
  &.no-links {
    margin-bottom: 20px;
  }
`;

const FooterContent = styled.div`
  padding-bottom: 1rem;
`;

const FooterLogo = styled.a`
  display: block;
  height: 30px;
  width: 100%;
  max-width: 265px;
  margin-bottom: 1.5rem;

  svg {
    width: 100%;

    .st0 {
      fill: white;
    }

    .st1 {
      fill: white;
    }
  }
  
  @media (min-width: ${props => props.theme.md}) {
    margin: 0 0 1rem;
  }
`;

const FooterLegal = styled.div`
  a {
    display: inline-block;
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0 2rem 1rem 0;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterLinkButton = styled(ButtonOutlineLight)`
  max-width: 265px;
  width: 100%;
  min-width: auto;
  
  @media (min-width: ${props => props.theme.lg}) {
    margin-right: 1rem;    
  }
`;

const FooterLogoContainer = styled.div`
  max-width: calc(265px + 1rem);
  text-align: center;
`;
